import HEADER from "./header.json";
import HERO from "./hero.json";
import LANDING from "./landing.json";

export const ENG = {
  translation: {
    HEADER,
    HERO,
    LANDING,
  },
};
