import React from "react";
import { EmblaOptionsType } from "embla-carousel";
import { useTranslation } from "react-i18next";

import {
  PrevButton,
  NextButton,
  usePrevNextButtons,
} from "./embla-carousel-arrow-buttons";
import useEmblaCarousel from "embla-carousel-react";
import { ItemInterface } from "../constant";
import { TextElement } from "../../../common/text";
import styled from "styled-components";
import {
  BorderRadiusData,
  BorderRadiusEnum,
  SizeContentData,
  SizeContentEnum,
} from "../../../theme/value";
import { FlexElement } from "../../../common/flex";
import { Spacing } from "../../../utils/spacing";
import { ColorEnum } from "../../../theme/color";

const EmblaCarousel: React.FC = () => {
  const { t } = useTranslation();
  const items = t("LANDING.TESTIMONIALS.SLIDE", {
    returnObjects: true,
  }) as ItemInterface[];

  console.log("items", items);

  const options: EmblaOptionsType = { align: "start" };

  const [emblaRef, emblaApi] = useEmblaCarousel(options);

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(emblaApi);

  return (
    <section className="embla">
      <div className="embla__viewport" ref={emblaRef}>
        <div className="embla__container">
          {items.map((e, index) => (
            <div className="embla__slide" key={index}>
              <Card>
                <PhotoImg src={e.img} alt={`photo slide index ${index}`} />
                <Content flexDirection="column">
                  <TitleContainer>
                    <Title size={20} weight="medium" tid={e.name} />
                    <RatingImg src="/svg/stars.svg" alt="rating image" />
                  </TitleContainer>
                  <FlexElement flexWrap="wrap" spacing={2} alignItems="center">
                    <Description color="graySecondary" size={16} tid={e.role} />
                    <Dot />
                    <Description
                      color="grayPrimary"
                      size={16}
                      tid={e.business}
                    />
                  </FlexElement>
                  <DescriptionText
                    size={16}
                    lineHeight={1.55}
                    color="grayPrimary"
                  >
                    {e.description}
                    <DescriptionText
                      tid={e.description_second}
                      size={16}
                      lineHeight={1.55}
                    />
                  </DescriptionText>
                </Content>
              </Card>
            </div>
          ))}
        </div>
      </div>

      <div className="embla__controls">
        <div className="embla__buttons">
          <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
          <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
        </div>
      </div>
    </section>
  );
};

export default EmblaCarousel;

const DescriptionText = styled(TextElement)`
  margin-top: ${Spacing(2)};

  @media screen and (max-width: ${SizeContentData[SizeContentEnum.Mobile]}) {
    font-size: 14px;
  }
`;

const Content = styled(FlexElement)`
  gap: 9px;

  @media screen and (max-width: ${SizeContentData[SizeContentEnum.Mobile]}) {
    gap: 5px;
  }
`;

const Dot = styled.div`
  width: 4px;
  height: 4px;
  border-radius: ${BorderRadiusData[BorderRadiusEnum.Circle]}px;
  background: rgba(152, 160, 176, 0.5);

  @media screen and (max-width: 360px) {
    display: none;
  }
`;

const PhotoImg = styled.img`
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: ${BorderRadiusData[BorderRadiusEnum.Photo]}px;
  position: relative;
  left: -4px;
  top: -4px;

  @media screen and (max-width: ${SizeContentData[SizeContentEnum.Mobile]}) {
    width: 48px;
    height: 48px;
    top: -6px;
  }
`;

const Card = styled.div`
  min-height: 222px;
  border-radius: ${BorderRadiusData[BorderRadiusEnum.Card]}px;
  padding: 31px;
  background-color: ${({ theme }) => theme[ColorEnum.White]};
  display: grid;
  grid-gap: ${Spacing(4)};
  grid-template-columns: 48px 1fr;

  @media screen and (max-width: ${SizeContentData[SizeContentEnum.Mobile]}) {
    min-height: 162px !important;
    padding: 26px 24px 24px 24px;
    grid-gap: ${Spacing(2)};
  }
`;

const RatingImg = styled.img`
  width: 70px;
  height: 16px;

  @media screen and (max-width: ${SizeContentData[SizeContentEnum.Mobile]}) {
    width: 66px;
    height: 14px;
  }
`;

const TitleContainer = styled.div``;

const Description = styled(TextElement)`
  @media screen and (max-width: ${SizeContentData[SizeContentEnum.Mobile]}) {
    font-size: 13px;
  }
`;

const Title = styled(TextElement)`
  margin-right: ${Spacing(2)};

  @media screen and (max-width: ${SizeContentData[SizeContentEnum.Mobile]}) {
    font-size: 15px;
    margin-right: 6px;
  }
`;
