import React from "react";
import styled, { css } from "styled-components";
import { useTranslation } from "react-i18next";

import { TextProps, TextStyledProps } from "./constant";
import { ColorEnum, colorData } from "../../theme/color";
import { FontWeightData } from "../../theme/value";

export const Element: React.FC<TextProps> = ({
  tid,
  tag,
  children,
  tvalue,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <TextStyled as={tag} {...props}>
      {tid ? t(tid, tvalue) : children}
    </TextStyled>
  );
};

const TextStyled = styled.span<TextStyledProps>`
  ${({
    size = 14,
    color = ColorEnum.TextPrimary,
    weight = "regular",
    lineHeight = 1,
    lineThrought = false,
    block = false,
    oneLine = false,
    isInline,
    textAlign,
    fontFamily = "Poppins",
  }) => {
    return css`
      font-family: ${fontFamily}, sans-serif;

      display: ${isInline ? "inline" : ""};
      font-size: ${size}px;
      text-align: ${textAlign ? textAlign : "left"};
      color: ${colorData[color]};
      font-weight: ${weight && FontWeightData[weight]} !important;
      text-decoration: ${lineThrought ? "line-through" : "none"};
      line-height: ${lineHeight}em;
      display: ${block ? "block" : ""};
      overflow: ${oneLine ? "hidden" : ""};
      text-overflow: ${oneLine ? "ellipsis" : ""};
      white-space: ${oneLine ? "nowrap" : ""};
    `;
  }};
`;
