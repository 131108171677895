import styled from "styled-components";
import { LayoutApp } from "./common/layout-app";
import { LayoutContent } from "./common/layout-content";
import { Cta } from "./module/cta/cta";
import { Features } from "./module/features/features";
import { FooterComponent } from "./module/footer/footer";
import { HeaderComponent } from "./module/header/header";
import { Hero } from "./module/hero/hero";
import { HowItWorks } from "./module/how-it-works/how-it-works";
import { Testimonials } from "./module/testimonials/testimonials";
import { Faq } from "./module/faq/faq";

function App() {
  return (
    <>
      <LayoutApp className="App">
        <HeaderComponent />
        <Hero />
        <Features />
        <HowItWorks />
        <Faq />
        <Testimonials />
        <Cta />
        <LayoutContent>
          <FooterComponent />
        </LayoutContent>
      </LayoutApp>
    </>
  );
}

export default App;
