import React, {
  ComponentPropsWithRef,
  useCallback,
  useEffect,
  useState,
} from "react";
import { EmblaCarouselType } from "embla-carousel";
import styled from "styled-components";
import { ColorEnum } from "../../../theme/color";
import {
  BorderRadiusData,
  BorderRadiusEnum,
  SizeContentData,
  SizeContentEnum,
} from "../../../theme/value";

type UsePrevNextButtonsType = {
  prevBtnDisabled: boolean;
  nextBtnDisabled: boolean;
  onPrevButtonClick: () => void;
  onNextButtonClick: () => void;
};

export const usePrevNextButtons = (
  emblaApi: EmblaCarouselType | undefined
): UsePrevNextButtonsType => {
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true);

  const onPrevButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollPrev();
  }, [emblaApi]);

  const onNextButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollNext();
  }, [emblaApi]);

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setPrevBtnDisabled(!emblaApi.canScrollPrev());
    setNextBtnDisabled(!emblaApi.canScrollNext());
  }, []);

  useEffect(() => {
    if (!emblaApi) return;

    onSelect(emblaApi);
    emblaApi.on("reInit", onSelect).on("select", onSelect);
  }, [emblaApi, onSelect]);

  return {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  };
};

type PropType = ComponentPropsWithRef<"button">;

export const PrevButton: React.FC<PropType> = (props) => {
  const { children, ...restProps } = props;

  return (
    <Button type="button" {...restProps}>
      <ArrowSvg
        src={
          restProps.disabled
            ? "/svg/arrow-left-disabled.svg"
            : "/svg/arrow-left.svg"
        }
        alt="arrow left"
      />
    </Button>
  );
};

export const NextButton: React.FC<PropType> = (props) => {
  const { children, ...restProps } = props;
  return (
    <Button type="button" {...restProps}>
      <ArrowSvg
        src={
          restProps.disabled
            ? "/svg/arrow-right-disabled.svg"
            : "/svg/arrow-right.svg"
        }
        alt="arrow right"
      />
    </Button>
  );
};

const ArrowSvg = styled.img`
  width: 22px;
  height: 22px;

  @media screen and (max-width: ${SizeContentData[SizeContentEnum.Mobile]}) {
    width: 20px;
    height: 20px;
  }
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme[ColorEnum.White]};
  cursor: pointer;
  border-radius: ${BorderRadiusData[BorderRadiusEnum.Circle]}px;
  padding: 10px;

  &:disabled {
    background: rgba(255, 255, 255, 0.75);
  }
`;
