import React from "react";
import ReactDOM from "react-dom/client";
import { ThemeProvider } from "styled-components";
import "./index.css";
import "./normalize.css";
import "./config/lang";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { colorData } from "./theme/color";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={colorData}>
      <App />
    </ThemeProvider>
  </React.StrictMode>
);

reportWebVitals();
