import React from "react";
import styled, { css } from "styled-components";

import { ButtonEnum, ButtonType, PropsElement } from "./constant";
import { Spacing } from "../../utils/spacing";
import { TextElement } from "../text";
import {
  ColorEnum,
  ColorType,
  GradienEnum,
  gradientData,
} from "../../theme/color";
import {
  BorderRadiusData,
  BorderRadiusEnum,
  FontWeightData,
  FontWeightEnum,
} from "../../theme/value";

export const Element: React.FC<PropsElement> = ({
  tid,
  fontSize = 14,
  textColor = "white",
  type,
  ...props
}) => {
  return (
    <Button type={type} {...props}>
      <TextElement
        fontFamily="Manrope"
        color={textColor}
        size={fontSize}
        tid={tid}
      />
    </Button>
  );
};

const Button = styled.button<{ background?: ColorType; type?: ButtonType }>`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;

  ${({ type }) => {
    if (type === ButtonEnum.PRIMARY) {
      return css`
        border-radius: ${BorderRadiusData[BorderRadiusEnum.Small]}px;
        background-color: ${({ theme }) => theme[ColorEnum.AccentPrimary]};
        padding: ${Spacing(3)} ${Spacing(8)};

        span {
          font-weight: ${FontWeightData[FontWeightEnum.ExtraBold]};
        }
      `;
    }
    if (type === ButtonEnum.CTO) {
      return css`
        border-radius: ${BorderRadiusData[BorderRadiusEnum.Default]}px;
        padding: ${Spacing(6)} ${Spacing(10)};
        background: ${gradientData[GradienEnum.Primary]};

        span {
          font-size: ${Spacing(5)} !important;
          font-weight: ${FontWeightData[FontWeightEnum.ExtraBold]} !important;
        }
      `;
    }
  }}

  ${({ background }) =>
    background &&
    css`
      background-color: ${background};
      background: ${background};
    `}
`;
