import React from "react";
import styled from "styled-components";
import { colorData, ColorEnum } from "../../theme/color";

export const Element: React.FC<{
  children?: React.ReactNode;
  className?: string;
}> = ({ children, className }) => {
  return <LayoutStyled className={className}>{children}</LayoutStyled>;
};

const LayoutStyled = styled.div`
  min-height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
  width: 100%;
  margin: 0 auto;
  overflow-x: clip;
  background-color: ${colorData[ColorEnum.Background]};

  @media screen and (max-width: 1135px) {
    display: inherit;
  }
`;
