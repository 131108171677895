export enum ColorEnum {
  White = "white",
  Black = "black",
  AccentPrimary = "accentPrimary",
  AccentSecondary = "accentSecondary",
  TextPrimary = "textPrimary",
  TextSecondary = "textSecondary",
  GrayPrimary = "grayPrimary",
  GraySecondary = "graySecondary",
  GrayThird = "grayThird",
  Background = "background",
}

export type ColorType = `${ColorEnum}`;

export const colorData = {
  [ColorEnum.White]: "#FFFFFF",
  [ColorEnum.Black]: "#000000",
  [ColorEnum.AccentPrimary]: "#3F88F5",
  [ColorEnum.AccentSecondary]: "#FF7C1D",
  [ColorEnum.TextPrimary]: "#111827",
  [ColorEnum.TextSecondary]: "#1D202A",
  [ColorEnum.GrayPrimary]: "#5F687B",
  [ColorEnum.GraySecondary]: "#98A0B0",
  [ColorEnum.GrayThird]: "#E1E5F1",
  [ColorEnum.Background]: "#F5F7FA",
};

export enum GradienEnum {
  Primary = "primary",
  Secondary = "secondary",
}

export type GradienType = `${GradienEnum}`;

export const gradientData = {
  [GradienEnum.Primary]: "linear-gradient(90deg, #5C9DFF, #3582F5)",
  [GradienEnum.Secondary]: "linear-gradient(90deg, #FF983A, #FF7C1D)",
};
