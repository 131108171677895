export enum TextAlignEnum {
  Start = "start",
  End = "end",
  Left = "left",
  Right = "right",
  Center = "center",
  Justify = "justify",
}

export type TextAlignType = `${TextAlignEnum}`;

export enum ValueOpacityEnum {
  Hover = "hover",
  Click = "click",
}

export type ValueOpacityType = `${ValueOpacityEnum}`;

export const ValueOpacityData = {
  [ValueOpacityEnum.Hover]: 0.7,
  [ValueOpacityEnum.Click]: 0.5,
};

export enum FontWeightEnum {
  Bold = "bold",
  Medium = "medium",
  Regular = "regular",
  SemiBold = "semi-bold",
  Light = "light",
  ExtraBold = "extra-bold",
}

export type FontWeightType = `${FontWeightEnum}`;

export const FontWeightData = {
  [FontWeightEnum.ExtraBold]: 800,
  [FontWeightEnum.Bold]: 700,
  [FontWeightEnum.SemiBold]: 600,
  [FontWeightEnum.Medium]: 500,
  [FontWeightEnum.Regular]: 400,
  [FontWeightEnum.Light]: 300,
};

export enum SizeContentEnum {
  Full = "full",
  Mobile = "mobile",
}

export type SizeContentType = `${SizeContentEnum}`;

export const SizeContentData = {
  [SizeContentEnum.Full]: "1200px",
  [SizeContentEnum.Mobile]: "850px",
};

export enum ValueTransitionEnum {
  Default = "default",
}

export type ValueTransitionType = `${ValueTransitionEnum}`;

export const ValueTransitionData = {
  [ValueTransitionEnum.Default]: "0.3s ease-in-out",
};

export enum BorderRadiusEnum {
  Default = "default",
  Small = "small",
  Medium = "medium",
  Card = "card",
  Circle = "circle",
  Block = "block",
  Photo = "photo",
}

export const BorderRadiusData = {
  [BorderRadiusEnum.Default]: 12,
  [BorderRadiusEnum.Small]: 8,
  [BorderRadiusEnum.Card]: 16,
  [BorderRadiusEnum.Medium]: 14,
  [BorderRadiusEnum.Circle]: 100,
  [BorderRadiusEnum.Block]: 20,
  [BorderRadiusEnum.Photo]: 10,
};

export type BorderRadiusType = `${BorderRadiusEnum}`;
