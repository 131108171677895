import React from "react";

import styled, { css } from "styled-components";
import { PropsElement } from "./constant";
import {
  SizeContentType,
  SizeContentData,
  SizeContentEnum,
} from "../../theme/value";
import { Spacing } from "../../utils/spacing";

export const Element: React.FC<PropsElement> = ({
  children,
  size,
  fullHeight = false,
  className,
  ...props
}) => {
  return (
    <LayoutContent
      {...props}
      className={className}
      size={size}
      fullHeight={fullHeight}
    >
      {children}
    </LayoutContent>
  );
};

const LayoutContent = styled.section<{
  size?: SizeContentType;
  fullHeight: boolean;
}>`
  display: flex;
  width: 100%;
  margin: 0 auto;
  padding: 0 ${Spacing(5)};
  flex-direction: column;

  ${({ size = SizeContentData[SizeContentEnum.Full], fullHeight }) => css`
    max-width: calc(${size} + 40px); // plus padding
    height: ${fullHeight ? "100%" : "auto"};
  `};
`;
