import styled from "styled-components";

import { Spacing } from "../../utils/spacing";
import { SizeContentData, SizeContentEnum } from "../../theme/value";
import { ButtonElement } from "../../common/button";
import { TextElement } from "../../common/text";
import { i18n } from "../../config/lang";
import { LayoutContent } from "../../common/layout-content";
import { FlexElement } from "../../common/flex";

export const Hero: React.FC = () => {
  return (
    <Wrapper>
      <Section>
        <ImageMobileContainer>
          <ImgMobile src="/img/hero-mobile.png" />
        </ImageMobileContainer>
        <Content flexDirection="column">
          <Title weight="medium" size={38} lineHeight={1.35}>
            {i18n.t("HERO.TITLE.0")}

            <Title
              isInline
              size={38}
              tag="h1"
              lineHeight={1.35}
              color="accentPrimary"
              weight="semi-bold"
              tid="HERO.TITLE.1"
            />
            {i18n.t("HERO.TITLE.2")}
          </Title>
          <Description tid="HERO.DESCRIPTION" lineHeight={1.5} size={18} />
          <ButtonElementStyled
            tid="HERO.BUTTON"
            type="cto"
            onClick={() => {}}
          />
        </Content>
      </Section>
    </Wrapper>
  );
};

const ImgMobile = styled.img`
  height: 345px;
  width: 844px;
  position: absolute;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ImageMobileContainer = styled.div`
  display: none;
  position: relative;
  background-size: auto;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  height: 345px;
  width: 100%;

  @media screen and (max-width: ${SizeContentData[SizeContentEnum.Mobile]}) {
    display: block;
  }
`;

const Content = styled(FlexElement)`
  width: max-content;

  @media screen and (max-width: ${SizeContentData[SizeContentEnum.Mobile]}) {
    width: 100%;
  }
`;

const ButtonElementStyled = styled(ButtonElement)`
  width: max-content;

  @media screen and (max-width: ${SizeContentData[SizeContentEnum.Mobile]}) {
    width: 100%;
    padding: ${Spacing(5)};

    span {
      font-size: 16px !important;
    }
  }
`;

const Description = styled(TextElement)`
  margin-bottom: ${Spacing(8)};
  max-width: ${Spacing(83)};

  @media screen and (max-width: ${SizeContentData[SizeContentEnum.Mobile]}) {
    font-size: 14px;
    margin-bottom: 50px;
  }

  @media screen and (max-width: ${SizeContentData[SizeContentEnum.Mobile]}) {
    max-width: unset;
  }
`;

const Title = styled(TextElement)`
  margin-bottom: ${Spacing(4)};
  max-width: ${Spacing(155)};

  @media screen and (max-width: ${SizeContentData[SizeContentEnum.Mobile]}) {
    font-size: ${Spacing(7)};
    margin-bottom: ${Spacing(3)};
  }

  @media screen and (max-width: ${SizeContentData[SizeContentEnum.Mobile]}) {
    max-width: unset;
  }
`;

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: start;

  background-image: url("/img/hero.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  @media screen and (max-width: ${SizeContentData[SizeContentEnum.Mobile]}) {
    background-image: unset;
    margin-top: 77px;
    min-height: unset;
  }
`;

const Section = styled(LayoutContent)`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  width: 100%;
  height: 100%;

  @media screen and (max-width: ${SizeContentData[SizeContentEnum.Mobile]}) {
    gap: ${Spacing(10)};
    padding-top: ${Spacing(5)};
    justify-content: start;
    padding-bottom: ${Spacing(13)};
  }
`;
