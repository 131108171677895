import { ColorType } from "../../theme/color";

export enum ButtonEnum {
  PRIMARY = "primary",
  CTO = "cto",
}

export type ButtonType = `${ButtonEnum}`;

export interface PropsElement {
  className?: string;
  tid?: string;
  type?: ButtonType;
  disabled?: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  background?: ColorType;
  fontSize?: number;
  textColor?: ColorType;
}
