import i18n from "i18next";

import { initReactI18next } from "react-i18next";

import { LangDefault } from "./constant";
import { LangTranslation } from "../../localization";

i18n.use(initReactI18next).init({
  resources: LangTranslation,
  fallbackLng: LangDefault,
  interpolation: {
    escapeValue: false,
  },
});

export { i18n };
