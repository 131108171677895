import styled from "styled-components";

import { Spacing } from "../../utils/spacing";
import { ColorEnum } from "../../theme/color";
import { FlexElement } from "../../common/flex";
import { TextElement } from "../../common/text";
import { headerList } from "./constant";
import { SizeContentData, SizeContentEnum } from "../../theme/value";
import { ButtonElement } from "../../common/button";
import { GridElement } from "../../common/grid";
import { i18n } from "../../config/lang";
import { MenuElem } from "../../common/menu";

export const HeaderComponent: React.FC = () => {
  return (
    <Header>
      <GridElementStyled tag="nav">
        <FlexElement alignItems="center">
          <MenuElem>
            <MobileList tag="ul">
              {headerList.map((e) => (
                <ItemLink key={e.tid} href={i18n.t(e.link)}>
                  <TextElement size={17} tid={e.tid} tag="li" />
                </ItemLink>
              ))}
            </MobileList>
          </MenuElem>
          <Logo src="/svg/logo.svg" />
          <LogoText src="/svg/logo-text.svg" />
        </FlexElement>
        <FlexElementStyled
          justifyContent="center"
          alignItems="center"
          spacing={6}
          tag="ul"
        >
          {headerList.map((e) => (
            <ItemLink key={e.tid} href={i18n.t(e.link)}>
              <TextElement size={16} tid={e.tid} tag="li" />
            </ItemLink>
          ))}
        </FlexElementStyled>
        <ButtonElementStyled
          type="primary"
          tid="HEADER.BUTTON.LOGIN"
          onClick={() => {}}
        />
      </GridElementStyled>
    </Header>
  );
};

const MobileList = styled(FlexElement)`
  display: none;
  list-style-type: none;

  @media screen and (max-width: ${SizeContentData[SizeContentEnum.Mobile]}) {
    display: flex;
    flex-direction: column;
    width: max-content;
    gap: 30px;
    padding: ${Spacing(8)} ${Spacing(5)};
  }
`;

const ButtonElementStyled = styled(ButtonElement)`
  @media screen and (max-width: ${SizeContentData[SizeContentEnum.Mobile]}) {
    max-width: 103px;
    span {
      font-size: 13px;
    }
  }
`;

const FlexElementStyled = styled(FlexElement)`
  list-style-type: none;

  @media screen and (max-width: ${SizeContentData[SizeContentEnum.Mobile]}) {
    display: none;
  }
`;

const GridElementStyled = styled(GridElement)`
  max-width: ${SizeContentData[SizeContentEnum.Full]};
  margin: 0 auto;
  grid-template-columns: 172px 1fr 105px;
  position: relative;

  @media screen and (max-width: ${SizeContentData[SizeContentEnum.Mobile]}) {
    grid-template-columns: 169px 105px;
    justify-content: space-between;
  }
`;

const LogoText = styled.img`
  width: 129px;
  min-width: 129px;
  min-height: 20px;
  height: 20px;

  @media screen and (max-width: ${SizeContentData[SizeContentEnum.Mobile]}) {
    width: 97px;
    min-width: 97px;
    min-height: 15px;
    height: 15px;
  }
`;

const Logo = styled.img`
  width: ${Spacing(9)};
  min-width: ${Spacing(9)};
  min-height: ${Spacing(8)};
  height: ${Spacing(8)};
  margin-right: 5px;

  @media screen and (max-width: ${SizeContentData[SizeContentEnum.Mobile]}) {
    width: 27px;
    min-width: 27px;
    min-height: ${Spacing(6)};
    height: ${Spacing(6)};
  }
`;

const ItemLink = styled.a`
  text-decoration: none;
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${Spacing(6)} ${Spacing(5)};
  background-color: ${({ theme }) => theme[ColorEnum.White]};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;

  @media screen and (max-width: ${SizeContentData[SizeContentEnum.Mobile]}) {
    padding: ${Spacing(5)};
  }
`;
