import styled from "styled-components";

import { Spacing } from "../../utils/spacing";
import { BorderRadiusData, BorderRadiusEnum } from "../../theme/value";
import { TextElement } from "../../common/text";
import { i18n } from "../../config/lang";
import { FlexElement } from "../../common/flex";
import { GridElement } from "../../common/grid";
import { cardList } from "./constant";
import { ColorEnum } from "../../theme/color";
import { LayoutContent } from "../../common/layout-content";
import { Accordion } from "../../common/accordion";

export const Faq: React.FC = () => {
  const accordionItems = [
    {
      title:
        "Can I get a subscription to your service for nothing but my pretty eyes?",
      content:
        "Using a service for a long time can raise concerns about eye strain, but fortunately, there are many ways to ensure your eyes stay comfortable and healthy. While extended screen use can sometimes lead to discomfort, it's not inevitable.",
    },
    {
      title: "If I use the service for a long time, will my eyes hurt?",
      content:
        "Using a service for a long time can raise concerns about eye strain, but fortunately, there are many ways to ensure your eyes stay comfortable and healthy. While extended screen use can sometimes lead to discomfort, it's not inevitable.",
    },
    {
      title:
        "Can I get a subscription to your service for nothing but my pretty eyes?",
      content:
        "Using a service for a long time can raise concerns about eye strain, but fortunately, there are many ways to ensure your eyes stay comfortable and healthy. While extended screen use can sometimes lead to discomfort, it's not inevitable.",
    },
    {
      title: "If I use the service for a long time, will my eyes hurt?",
      content:
        "Using a service for a long time can raise concerns about eye strain, but fortunately, there are many ways to ensure your eyes stay comfortable and healthy. While extended screen use can sometimes lead to discomfort, it's not inevitable.",
    },
  ];

  return (
    <LayoutContent>
      <Wrapper flexDirection="column" spacing={10}>
        <GridElement spacing={3} justifyItems="center">
          <Title size={32} weight="semi-bold" tid="LANDING.FAQ.TITLE" />
          <Description
            tid="LANDING.FAQ.DESCRIPTION"
            color="grayPrimary"
            size={17}
          />
        </GridElement>
        <Accordion items={accordionItems} />
      </Wrapper>
    </LayoutContent>
  );
};

const Title = styled(TextElement)`
  @media screen and (max-width: 1005px) {
    font-size: 26px;
  }
`;

const Description = styled(TextElement)`
  @media screen and (max-width: 1005px) {
    font-size: 15px;
    display: none;
  }
`;

const Wrapper = styled(FlexElement)`
  padding: ${Spacing(18)} 0;
  text-align: center;
  width: 100%;

  @media screen and (max-width: 1005px) {
    gap: ${Spacing(6)};
    padding: ${Spacing(10)} 0;
  }
`;
