import React, { ReactNode } from "react";
import styled, { SupportedHTMLElements } from "styled-components";

import { Spacing } from "../../utils/spacing";

export const Element: React.FC<{
  children: ReactNode;
  tag?: SupportedHTMLElements;
  spacing?: number;
  className?: string;
  justifyItems?: "start" | "end" | "center" | "stretch";
}> = ({ children, tag, spacing, className, justifyItems = "start" }) => {
  return (
    <Grid
      as={tag}
      justifyItems={justifyItems}
      className={className}
      spacing={spacing}
    >
      {children}
    </Grid>
  );
};

const Grid = styled.div<{
  spacing?: number;
  justifyItems?: "start" | "end" | "center" | "stretch";
}>`
  display: grid;
  width: 100%;
  grid-gap: ${({ spacing }) => (spacing ? Spacing(spacing) : "0")};
  justify-items: ${({ justifyItems }) => justifyItems || "stretch"};
`;
