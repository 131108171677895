import React from "react";
import styled from "styled-components";

import { PropsElement, PropsStyled } from "./constant";
import { Spacing } from "../../utils/spacing";

export const Element: React.FC<PropsElement> = ({
  children,
  tag,
  ...props
}) => {
  return (
    <ElementContainer as={tag} {...props}>
      {children}
    </ElementContainer>
  );
};

const ElementContainer = styled.div<PropsStyled>`
  display: flex;
  width: 100%;
  gap: ${({ spacing }) => (spacing ? Spacing(spacing) : "")};
  justify-content: ${({ justifyContent }) => justifyContent || ""};
  align-items: ${({ alignItems }) => alignItems || ""};
  flex-direction: ${({ flexDirection }) => flexDirection || ""};
  flex-wrap: ${({ flexWrap }) => flexWrap || ""};
`;
