import styled from "styled-components";

import { Spacing } from "../../utils/spacing";
import { BorderRadiusData, BorderRadiusEnum } from "../../theme/value";
import { TextElement } from "../../common/text";
import { i18n } from "../../config/lang";
import { FlexElement } from "../../common/flex";
import { GridElement } from "../../common/grid";
import { cardList } from "./constant";
import { ColorEnum } from "../../theme/color";
import { LayoutContent } from "../../common/layout-content";

export const Features: React.FC = () => {
  return (
    <LayoutContent>
      <Wrapper flexDirection="column" spacing={10}>
        <GridElement spacing={3} justifyItems="center">
          <Title size={32} weight="semi-bold" tid="LANDING.FEATURES.TITLE" />
          <Description color="grayPrimary" size={17}>
            {i18n.t("LANDING.FEATURES.DESCRIPTION.0")}
            <Description
              weight="medium"
              tid="LANDING.FEATURES.DESCRIPTION.1"
              color="textPrimary"
              size={17}
            />
          </Description>
        </GridElement>
        <List>
          {cardList.map((e, index) => (
            <Card index={index} alignItems="center" spacing={6}>
              <ImgContainer
                backgroundColor={e.backgroundColor}
                alignItems="center"
                justifyContent="center"
              >
                <Img src={e.img} />
              </ImgContainer>
              <CardInfo spacing={2}>
                <CardTitle tid={e.title} size={24} weight="semi-bold" />
                <CardDescripton
                  lineHeight={1.55}
                  tid={e.description}
                  color="grayPrimary"
                  size={16}
                />
              </CardInfo>
            </Card>
          ))}
        </List>
      </Wrapper>
    </LayoutContent>
  );
};

const CardInfo = styled(GridElement)`
  @media screen and (max-width: 1005px) {
    grid-gap: 6px;
  }
`;

const CardTitle = styled(TextElement)`
  @media screen and (max-width: 1005px) {
    font-size: 20px;
  }
`;

const CardDescripton = styled(TextElement)`
  max-width: 277px;

  @media screen and (max-width: 1005px) {
    font-size: 14px;
  }

  @media screen and (max-width: 1005px) {
    max-width: 305px;
  }
`;

const Img = styled.img`
  width: ${Spacing(10)};
  height: ${Spacing(10)};

  @media screen and (max-width: 1005px) {
    width: ${Spacing(9)};
    height: ${Spacing(9)};
  }
`;

const ImgContainer = styled(FlexElement)<{ backgroundColor: string }>`
  background: ${({ backgroundColor }) => backgroundColor};
  border-radius: ${BorderRadiusData[BorderRadiusEnum.Default]}px;
  width: 100%;
  max-width: ${Spacing(23)};
  height: ${Spacing(23)};

  @media screen and (max-width: 1005px) {
    max-width: ${Spacing(19)};
    height: ${Spacing(19)};
  }
`;

const List = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${Spacing(5)};

  grid-template-areas:
    "item0 item1"
    "item2 item3";

  @media screen and (max-width: 1005px) {
    grid-template-columns: 1fr;
    grid-gap: ${Spacing(4)};

    grid-template-areas:
      "item0"
      "item1"
      "item3"
      "item2";
  }
`;

const Card = styled(FlexElement)<{ index: number }>`
  border-radius: ${BorderRadiusData[BorderRadiusEnum.Card]}px;
  background-color: ${({ theme }) => theme[ColorEnum.White]};
  padding: ${Spacing(7)};

  grid-area: ${({ index }) => `item${index}`};

  @media screen and (max-width: 1005px) {
    flex-direction: column;
    align-items: start;
    gap: ${Spacing(5)};
    padding: ${Spacing(7)} ${Spacing(6)};
  }
`;

const Title = styled(TextElement)`
  @media screen and (max-width: 1005px) {
    font-size: 26px;
  }
`;

const Description = styled(TextElement)`
  @media screen and (max-width: 1005px) {
    font-size: 15px;
  }
`;

const Wrapper = styled(FlexElement)`
  margin: ${Spacing(18)} 0 ${Spacing(15)};
  text-align: center;
  width: 100%;

  @media screen and (max-width: 1005px) {
    gap: ${Spacing(6)};
    margin: ${Spacing(10)} 0;
  }
`;
