import styled from "styled-components";

import { Spacing } from "../../utils/spacing";
import { FlexElement } from "../../common/flex";
import { TextElement } from "../../common/text";
import { headerList } from "./constant";
import { SizeContentData, SizeContentEnum } from "../../theme/value";

export const FooterComponent: React.FC = () => {
  return (
    <Footer>
      <ListContainer alignItems="center" spacing={8}>
        <Logo src="/svg/logotype-footer.svg" alt="logo" />
        <List spacing={4} tag="ul">
          {headerList.map((e) => (
            <ItemLink href={e.link} key={e.tid}>
              <ListElem
                tag="li"
                oneLine
                lineHeight={1.5}
                size={16}
                color="graySecondary"
                tid={e.tid}
              />
            </ItemLink>
          ))}
        </List>
      </ListContainer>
      <FlexElementStyled flexDirection="column" spacing={3}>
        <FlexElement spacing={1}>
          <TextElement
            oneLine
            weight="medium"
            color="grayPrimary"
            size={14}
            tid="LANDING.FOOTER.MAIL"
          />
          <TextElement
            oneLine
            color="grayPrimary"
            size={14}
            tid="LANDING.FOOTER.PHONE"
          />
        </FlexElement>
        <TextElement
          oneLine
          size={14}
          tid="LANDING.FOOTER.ADRESS"
          color="graySecondary"
        />
      </FlexElementStyled>
    </Footer>
  );
};

const ListContainer = styled(FlexElement)`
  @media screen and (max-width: ${SizeContentData[SizeContentEnum.Mobile]}) {
    flex-direction: column;
    align-items: start;
    gap: ${Spacing(8)};
  }
`;

const List = styled(FlexElement)`
  line-height: 1em;

  @media screen and (max-width: ${SizeContentData[SizeContentEnum.Mobile]}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-width: 206px;

    row-gap: ${Spacing(3)};
  }
`;

const FlexElementStyled = styled(FlexElement)`
  width: max-content;

  @media screen and (max-width: ${SizeContentData[SizeContentEnum.Mobile]}) {
    gap: ${Spacing(4)};
  }
`;

const ListElem = styled(TextElement)`
  list-style-type: none;

  @media screen and (max-width: ${SizeContentData[SizeContentEnum.Mobile]}) {
    font-size: 14px;
  }
`;

const ItemLink = styled.a`
  text-decoration: none;
  line-height: 1em;
`;

const Logo = styled.img`
  width: 171.68px;
  height: ${Spacing(8)};

  @media screen and (max-width: ${SizeContentData[SizeContentEnum.Mobile]}) {
    width: 107px;
    height: ${Spacing(5)};
  }
`;

const Footer = styled.footer`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${Spacing(5)} 0 ${Spacing(10)};

  @media screen and (max-width: 1010px) {
    flex-direction: column;
    align-items: start;
    gap: ${Spacing(8)};
  }

  @media screen and (max-width: ${SizeContentData[SizeContentEnum.Mobile]}) {
    padding: ${Spacing(4)} 0 ${Spacing(8)};
  }
`;
