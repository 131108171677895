import styled from "styled-components";

import { Spacing } from "../../utils/spacing";
import {
  BorderRadiusData,
  BorderRadiusEnum,
  SizeContentData,
  SizeContentEnum,
} from "../../theme/value";
import { TextElement } from "../../common/text";
import { i18n } from "../../config/lang";
import { GridElement } from "../../common/grid";
import { ColorEnum } from "../../theme/color";
import { LayoutContent } from "../../common/layout-content";

export const HowItWorks: React.FC = () => {
  return (
    <>
      <GridElementStyled spacing={3} justifyItems="center">
        <Title size={32} weight="semi-bold">
          {i18n.t("LANDING.HOW_WORKS.TITLE.0")}
          <Title
            tid="LANDING.HOW_WORKS.TITLE.1"
            size={32}
            weight="semi-bold"
            color="accentPrimary"
          />
        </Title>
        <Description
          color="grayPrimary"
          tid="LANDING.HOW_WORKS.DESCRIPTION"
          size={17}
        />
      </GridElementStyled>
      <Wrapper>
        <Img src="/svg/honeycombs.svg" alt="honeycombs" />
        <StepContainer>
          <TextElemStyled
            size={20}
            tid="LANDING.HOW_WORKS.STEP.0"
            color="textSecondary"
          />
          <TextNumber weight="bold" size={36} tid="01" color="accentPrimary" />

          <FixedTwoNumberPosition
            weight="bold"
            size={36}
            tid="02"
            color="accentPrimary"
          />

          <FixedPosition
            size={20}
            tid="LANDING.HOW_WORKS.STEP.1"
            color="textSecondary"
          />

          <StepThirdText
            size={20}
            tid="LANDING.HOW_WORKS.STEP.2"
            color="graySecondary"
          />
          <StepLine src="/svg/step-line.svg" alt="center line" />
          <TextNumber weight="bold" size={36} tid="03" color="grayThird" />
          <LeftColumnNumber
            weight="bold"
            size={36}
            tid="04"
            color="grayThird"
          />

          <TextElemStyled
            size={20}
            tid="LANDING.HOW_WORKS.STEP.3"
            color="graySecondary"
          />
        </StepContainer>

        <StepContainerMobile>
          <StepLineMobile src="/svg/step-line-mobile.svg" alt="center line" />
          <TextContainer>
            <TextNumber
              weight="bold"
              size={36}
              tid="01"
              color="accentPrimary"
            />

            <TextElemStyled
              size={20}
              tid="LANDING.HOW_WORKS.STEP.0"
              color="textSecondary"
            />

            <FixedTwoNumberPosition
              weight="bold"
              size={36}
              tid="02"
              color="accentPrimary"
            />

            <FixedPosition
              size={20}
              tid="LANDING.HOW_WORKS.STEP.1"
              color="textSecondary"
            />
            <TextNumber weight="bold" size={36} tid="03" color="grayThird" />

            <StepThirdText
              size={20}
              tid="LANDING.HOW_WORKS.STEP.2"
              color="graySecondary"
            />
            <LeftColumnNumber
              weight="bold"
              size={36}
              tid="04"
              color="grayThird"
            />

            <TextElemStyled
              size={20}
              tid="LANDING.HOW_WORKS.STEP.3"
              color="graySecondary"
            />
          </TextContainer>
        </StepContainerMobile>
      </Wrapper>
    </>
  );
};

const StepLineMobile = styled.img`
  position: relative;
  left: 6px;
  top: 4px;
`;

const StepContainerMobile = styled.div`
  display: none;

  @media screen and (max-width: ${SizeContentData[SizeContentEnum.Mobile]}) {
    display: grid;
    grid-template-columns: ${Spacing(4)} 1fr;
    grid-gap: ${Spacing(7)};
  }
`;

const TextContainer = styled.div`
  display: grid;
  grid-template-columns: 32px 1fr;
  align-items: center;
  grid-gap: ${Spacing(15)} ${Spacing(2)};
`;

const StepLine = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media screen and (max-width: ${SizeContentData[SizeContentEnum.Mobile]}) {
    display: none;
  }
`;

const TextNumber = styled(TextElement)`
  @media screen and (max-width: ${SizeContentData[SizeContentEnum.Mobile]}) {
    font-size: ${Spacing(6)};
  }
`;

const LeftColumnNumber = styled(TextNumber)`
  justify-self: end;

  @media screen and (max-width: ${SizeContentData[SizeContentEnum.Mobile]}) {
    justify-self: unset;
  }
`;

const FixedTwoNumberPosition = styled(LeftColumnNumber)`
  position: relative;
  top: 3px;

  @media screen and (max-width: ${SizeContentData[SizeContentEnum.Mobile]}) {
    position: unset;
  }
`;

const TextElemStyled = styled(TextElement)`
  text-align: end;

  @media screen and (max-width: ${SizeContentData[SizeContentEnum.Mobile]}) {
    font-size: ${Spacing(4)};
    text-align: unset;
  }
`;

const StepThirdText = styled(TextElemStyled)`
  position: relative;
  right: 20px;

  @media screen and (max-width: ${SizeContentData[SizeContentEnum.Mobile]}) {
    position: unset;
  }
`;

const FixedPosition = styled(TextElement)`
  position: relative;
  top: 3px;

  @media screen and (max-width: ${SizeContentData[SizeContentEnum.Mobile]}) {
    font-size: ${Spacing(4)};
    position: unset;
  }
`;

const StepContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-gap: ${Spacing(16)};
  align-items: center;
  height: 100%;

  @media screen and (max-width: ${SizeContentData[SizeContentEnum.Mobile]}) {
    display: none;
  }
`;

const Img = styled.img`
  position: absolute;
  left: 65px;
  bottom: 75px;

  @media screen and (max-width: 1350px) {
    display: none;
  }
`;

const GridElementStyled = styled(GridElement)`
  margin-top: ${Spacing(10)};
  margin-bottom: ${Spacing(8)};

  @media screen and (max-width: ${SizeContentData[SizeContentEnum.Mobile]}) {
    margin-bottom: ${Spacing(6)};
    grid-gap: 8px;
  }
`;

const Title = styled(TextElement)`
  @media screen and (max-width: ${SizeContentData[SizeContentEnum.Mobile]}) {
    font-size: 26px;
  }
`;

const Description = styled(TextElement)`
  @media screen and (max-width: ${SizeContentData[SizeContentEnum.Mobile]}) {
    font-size: 15px;
  }
`;

const Wrapper = styled.section`
  display: flex;
  align-items: center;
  justify-content: start;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme[ColorEnum.White]};
  border-radius: ${BorderRadiusData[BorderRadiusEnum.Block]}px;
  max-width: ${Spacing(348)};
  width: 100%;
  margin: 0 auto;
  margin-bottom: ${Spacing(6)};
  position: relative;
  padding: 38px ${Spacing(7)};
  flex-direction: column;

  min-height: 554px;

  @media screen and (max-width: 1416px) {
    width: unset;
    margin: 0 ${Spacing(3)};
    margin-bottom: ${Spacing(6)};
  }

  @media screen and (max-width: ${SizeContentData[SizeContentEnum.Mobile]}) {
    margin-bottom: 0;
    align-items: start;
    min-height: unset;
    padding: ${Spacing(11)} ${Spacing(5)};
    border-radius: ${BorderRadiusData[BorderRadiusEnum.Medium]}px;
  }
`;
