import React, { ReactNode, useState } from "react";
import styled, { css } from "styled-components";

import { SizeContentData, SizeContentEnum } from "../../theme/value";
import { Spacing } from "../../utils/spacing";
import { ColorEnum } from "../../theme/color";

export const Elem: React.FC<{
  children?: ReactNode;
  title?: string;
}> = ({ children, title }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen((prev) => !prev);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <ButtonStyled onClick={handleClick}>
        {!isOpen && <MenuImg alt="menu" src="/svg/menu.svg" />}
        {isOpen && <MenuImgClose alt="close" src="/svg/close.svg" />}
      </ButtonStyled>
      <ModalOverlay isOpen={isOpen} onClick={handleClose}>
        <Menu onClick={(e) => e.stopPropagation()} isOpen={isOpen}>
          {children}
        </Menu>
      </ModalOverlay>
    </>
  );
};

const MenuImgClose = styled.img`
  width: 18px;
  height: 18px;
`;

const MenuImg = styled.img`
  width: ${Spacing(6)};
  height: ${Spacing(6)};
`;

const ButtonStyled = styled.button`
  display: none;
  width: 24px;

  @media screen and (max-width: ${SizeContentData[SizeContentEnum.Mobile]}) {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: ${Spacing(4)};
  }
`;

const ModalOverlay = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 77px;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: rgba(20, 18, 26, 0.6); */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: visibility 0.5s, opacity 0.5s ease-in-out;

  ${({ isOpen }) =>
    isOpen &&
    css`
      visibility: visible;
      opacity: 1;
      transition: visible 0.5;
    `}
`;
const Menu = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: ${({ isOpen }) => (isOpen ? "0" : "-375px")};
  left: 0;
  width: 100vw;
  max-width: 100%;
  height: auto;
  background-color: ${({ theme }) => theme[ColorEnum.White]};
  position: absolute;
  display: flex;
  flex-direction: column;
  transition: top 0.2s ease-in-out;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  z-index: 1000;
  box-shadow: 0px 70px 70px -70px rgba(31, 83, 160, 0.15);
`;
