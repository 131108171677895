import styled from "styled-components";

import { Spacing } from "../../utils/spacing";
import { BorderRadiusData, BorderRadiusEnum } from "../../theme/value";
import { TextElement } from "../../common/text";
import { ColorEnum } from "../../theme/color";
import { ButtonElement } from "../../common/button";
import { FlexElement } from "../../common/flex";

export const Cta: React.FC = () => {
  return (
    <Wrapper>
      <ColumnContainer>
        <Column>
          <Title
            fontFamily="Manrope"
            color="white"
            size={44}
            weight="bold"
            tid="LANDING.CTA.TITLE"
          />
          <Description
            fontFamily="Manrope"
            color="white"
            size={20}
            weight="semi-bold"
            tid="LANDING.CTA.DESCRIPTION"
          />
          <ButtonElementStyled
            textColor="accentPrimary"
            tid="LANDING.CTA.BUTTON"
            onClick={() => {}}
            type="cto"
            background="white"
          />
        </Column>
        <ColumnPrice>
          <PriceTitle>
            <Price
              color="accentPrimary"
              fontFamily="Manrope"
              size={40}
              lineHeight={1.25}
              weight="bold"
              tid="LANDING.CTA.PRICE"
            />
            <FlexElementStyled flexDirection="column">
              <PerMonth
                tid="LANDING.CTA.PER_MONTH"
                weight="semi-bold"
                size={18}
                fontFamily="Manrope"
              />
              <Monthly
                color="graySecondary"
                tid="LANDING.CTA.MONTHLY"
                size={14}
                fontFamily="Manrope"
              />
            </FlexElementStyled>
          </PriceTitle>
          <PriceDescription>
            <PriceDecription
              lineHeight={1.55}
              color="white"
              size={18}
              fontFamily="Manrope"
              weight="medium"
              tid="LANDING.CTA.PRICE_DESCRIPTION"
            />
          </PriceDescription>
        </ColumnPrice>
        <ButtonElementMobile
          textColor="accentPrimary"
          tid="LANDING.CTA.BUTTON"
          onClick={() => {}}
          type="cto"
          background="white"
        />
      </ColumnContainer>
    </Wrapper>
  );
};

const PriceDecription = styled(TextElement)`
  @media screen and (max-width: 900px) {
    font-size: 14px;
  }
`;

const PerMonth = styled(TextElement)`
  @media screen and (max-width: 900px) {
    font-size: 15px;
  }
`;

const Monthly = styled(TextElement)`
  @media screen and (max-width: 900px) {
    font-size: 13px;
  }
`;

const Price = styled(TextElement)`
  @media screen and (max-width: 900px) {
    font-size: 36px;
  }
  @media screen and (max-width: 375px) {
    font-size: 25px;
  }
`;

const FlexElementStyled = styled(FlexElement)`
  width: unset;
  gap: 2px;
`;

const PriceDescription = styled.div`
  padding: ${Spacing(6)};

  @media screen and (max-width: 900px) {
    padding: ${Spacing(5)};
  }
`;

const PriceTitle = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme[ColorEnum.White]};
  justify-content: space-between;
  padding: ${Spacing(5)} ${Spacing(6)};
  align-items: center;
  border-top-left-radius: 11px;
  border-top-right-radius: 11px;
  height: 100%;
`;

const ButtonElementStyled = styled(ButtonElement)`
  width: 100%;

  @media screen and (max-width: 900px) {
    display: none;
  }
`;

const ButtonElementMobile = styled(ButtonElement)`
  display: none;

  @media screen and (max-width: 900px) {
    display: flex;
    margin-top: ${Spacing(7)};
    padding: ${Spacing(5)};

    span {
      font-size: ${Spacing(4)} !important;
    }
  }
`;

const ColumnContainer = styled.div`
  grid-gap: ${Spacing(16)};
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-columns: repeat(2, minmax(0, 450px));
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 900px) {
    grid-template-columns: 1fr;
    grid-gap: 26px;
  }
`;

const Description = styled(TextElement)`
  margin-bottom: ${Spacing(9)};

  @media screen and (max-width: 900px) {
    font-size: 15px;
    margin-bottom: 0px;
  }
`;

const Title = styled(TextElement)`
  margin-bottom: 20px;

  @media screen and (max-width: 900px) {
    font-size: 30px;
    margin-bottom: 14px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ColumnPrice = styled.div`
  display: grid;
  border-radius: ${BorderRadiusData[BorderRadiusEnum.Card]}px;
  border: 2px solid ${({ theme }) => theme[ColorEnum.White]};
  grid-template-rows: 88px 1fr;
  height: 100%;
  align-items: center;
  backdrop-filter: blur(100px);
  @media screen and (max-width: 900px) {
    border-radius: ${BorderRadiusData[BorderRadiusEnum.Medium]}px;
    grid-template-rows: 82px 1fr;
  }
`;

const Wrapper = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${({ theme }) => theme[ColorEnum.AccentPrimary]};
  border-radius: ${BorderRadiusData[BorderRadiusEnum.Card]}px;

  @media screen and (max-width: 900px) {
    border-radius: ${BorderRadiusData[BorderRadiusEnum.Medium]}px;
  }

  max-width: ${Spacing(348)};
  width: 100%;

  margin-bottom: ${Spacing(6)};
  padding: ${Spacing(25)} ${Spacing(7)};
  margin: ${Spacing(6)} auto;

  background-image: url("/svg/background-cto.svg");
  background-position: center 525px;

  min-height: 425px;

  @media screen and (max-width: 1416px) {
    width: unset;
    margin: ${Spacing(6)} ${Spacing(3)};
  }

  @media screen and (max-width: 900px) {
    padding: ${Spacing(14)} ${Spacing(4)};
  }
`;
