import styled from "styled-components";
import "./style.css";

import EmblaCarousel from "./component/embla-carousel";
import { TextElement } from "../../common/text";
import { Spacing } from "../../utils/spacing";
import { SizeContentData, SizeContentEnum } from "../../theme/value";
import { FlexElement } from "../../common/flex";
import { i18n } from "../../config/lang";

export const Testimonials: React.FC = () => {
  return (
    <Wrapper>
      <TitleContainer
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        spacing={3}
      >
        <Title
          tid="LANDING.TESTIMONIALS.TITLE"
          weight="semi-bold"
          size={32}
          lineHeight={1.35}
        />
        <FlexElement spacing={2} alignItems="center" justifyContent="center">
          <FlexStyled spacing={1} alignItems="center">
            <StartImg src="/svg/one-star.svg" alt="star" />
            <Rating weight="semi-bold" size={20}>
              {i18n.t("LANDING.TESTIMONIALS.RATING_NUMBER")}
              <Rating
                weight="medium"
                size={20}
                tid="LANDING.TESTIMONIALS.RATING_TEXT"
              />
            </Rating>
          </FlexStyled>
          <Description
            color="grayPrimary"
            size={17}
            tid="LANDING.TESTIMONIALS.DESCRIPTION"
          />
        </FlexElement>
      </TitleContainer>
      <EmblaCarousel />
    </Wrapper>
  );
};

const Rating = styled(TextElement)`
  line-height: 0.7em;

  @media screen and (max-width: ${SizeContentData[SizeContentEnum.Mobile]}) {
    font-size: 16px;
  }
`;

const Title = styled(TextElement)`
  @media screen and (max-width: ${SizeContentData[SizeContentEnum.Mobile]}) {
    font-size: 26px;
  }
`;

const Description = styled(TextElement)`
  @media screen and (max-width: ${SizeContentData[SizeContentEnum.Mobile]}) {
    font-size: 14px;
  }
`;

const FlexStyled = styled(FlexElement)`
  width: unset;
`;

const StartImg = styled.img`
  height: ${Spacing(5)};
  width: ${Spacing(5)};

  @media screen and (max-width: ${SizeContentData[SizeContentEnum.Mobile]}) {
    height: ${Spacing(4)};
    width: ${Spacing(4)};
  }
`;

const TitleContainer = styled(FlexElement)`
  margin-bottom: 39px;

  @media screen and (max-width: ${SizeContentData[SizeContentEnum.Mobile]}) {
    margin-bottom: 26px;
    gap: ${Spacing(2)};
  }
`;

const Wrapper = styled.section`
  position: relative;
  padding: ${Spacing(18)} ${Spacing(5)} ${Spacing(16)};

  @media screen and (max-width: ${SizeContentData[SizeContentEnum.Mobile]}) {
    padding: ${Spacing(10)} ${Spacing(5)};
  }
`;
