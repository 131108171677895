import React, { useState, useRef, useEffect, ReactNode } from "react";
import styled, { css } from "styled-components";
import { TextElement } from "../text";
import { Spacing } from "../../utils/spacing";
import { ColorEnum } from "../../theme/color";
import {
  BorderRadiusData,
  BorderRadiusEnum,
  SizeContentData,
  SizeContentEnum,
} from "../../theme/value";

interface AccordionItemProps {
  title: string;
  children: ReactNode;
  isOpen: boolean;
  onClick: () => void;
}

const AccordionItem: React.FC<AccordionItemProps> = ({
  title,
  children,
  isOpen,
  onClick,
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<number>(0);

  useEffect(() => {
    if (contentRef.current) {
      setHeight(isOpen ? contentRef.current.scrollHeight : 0);
    }
  }, [isOpen]);

  return (
    <AccordionContainer isOpen={isOpen}>
      <AccordionHeader onClick={onClick}>
        <Title size={20} weight="medium" lineHeight={1.4} tid={title} />
        <Chevron icon="/svg/arrow-accordion.svg" isOpen={isOpen}></Chevron>
      </AccordionHeader>
      <AccordionContent ref={contentRef} height={height}>
        {children}
      </AccordionContent>
    </AccordionContainer>
  );
};

interface AccordionProps {
  items: { title: string; content: string }[];
}

export const Element: React.FC<AccordionProps> = ({ items }) => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const handleToggle = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div>
      {items.map((item, index) => (
        <AccordionItem
          key={index}
          title={item.title}
          isOpen={openIndex === index}
          onClick={() => handleToggle(index)}
        >
          <Content
            color="grayPrimary"
            size={18}
            lineHeight={1.55}
            tid={item.content}
          />
        </AccordionItem>
      ))}
    </div>
  );
};

const Title = styled(TextElement)`
  @media screen and (max-width: ${SizeContentData[SizeContentEnum.Mobile]}) {
    font-size: 15px;
  }
`;

const Content = styled(TextElement)`
  @media screen and (max-width: ${SizeContentData[SizeContentEnum.Mobile]}) {
    font-size: 14px;
  }
`;

const AccordionContainer = styled.div<{ isOpen: boolean }>`
  width: 100%;
  border-radius: ${BorderRadiusData[BorderRadiusEnum.Medium]}px;
  overflow: hidden;
  margin-bottom: ${Spacing(3)};

  border: 1.5px solid
    ${({ isOpen, theme }) =>
      isOpen ? theme[ColorEnum.AccentPrimary] : "white"};
  transition: border-color 0.3s ease;
`;

const AccordionHeader = styled.div`
  background-color: ${({ theme }) => theme[ColorEnum.White]};
  color: white;
  padding: ${Spacing(6)};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: ${SizeContentData[SizeContentEnum.Mobile]}) {
    padding: ${Spacing(5)};
  }
`;

const AccordionContent = styled.div<{ height: number }>`
  height: ${({ height }) => (height ? `${height + 30}px` : "0")};
  overflow: hidden;
  transition: height 0.3s ease;
  /* padding: ${({ height }) => (height ? "0 15px 15px" : "0 15px")}; */
  /* position: relative; */
  /* top: 15px; */

  padding: 0 ${Spacing(6)};
  background-color: ${({ theme }) => theme[ColorEnum.White]};
  text-align: start;

  @media screen and (max-width: ${SizeContentData[SizeContentEnum.Mobile]}) {
    padding: 0 ${Spacing(5)};
  }
`;

const Chevron = styled.div<{ icon: string; isOpen?: boolean }>`
  height: 22px;
  width: 22px;

  transform: ${({ isOpen }) => (isOpen ? "rotate(180deg)" : "rotate(0deg)")};
  transition: transform 0.3s ease;

  ${({ icon }) => css`
    -webkit-mask: url(${icon}) no-repeat center;
    mask: url(${icon}) no-repeat center;
    z-index: 1;
    background-color: ${({ theme }) => theme[ColorEnum.GraySecondary]};
  `}

  ${({ isOpen }) =>
    isOpen
      ? css`
          background-color: ${({ theme }) => theme[ColorEnum.AccentPrimary]};
        `
      : ""};

  @media screen and (max-width: ${SizeContentData[SizeContentEnum.Mobile]}) {
    height: ${Spacing(5)};
    width: ${Spacing(5)};
  }
`;
