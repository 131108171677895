export const cardList = [
  {
    img: "/svg/empty-wallet-change.svg",
    title: "LANDING.FEATURES.CARD.0.TITLE",
    description: "LANDING.FEATURES.CARD.0.DESCRIPTION",
    backgroundColor:
      "linear-gradient(90deg, rgba(92, 157, 255, 0.1), rgba(53, 130, 245, 0.1))",
  },
  {
    img: "/svg/document-copy.svg",
    title: "LANDING.FEATURES.CARD.1.TITLE",
    description: "LANDING.FEATURES.CARD.1.DESCRIPTION",
    backgroundColor:
      "linear-gradient(90deg, rgba(255, 152, 58, 0.1), rgba(255, 124, 29, 0.1))",
  },
  {
    img: "/svg/setting-3.svg",
    title: "LANDING.FEATURES.CARD.2.TITLE",
    description: "LANDING.FEATURES.CARD.2.DESCRIPTION",
    backgroundColor:
      "linear-gradient(90deg, rgba(255, 152, 58, 0.1), rgba(255, 124, 29, 0.1))",
  },
  {
    img: "/svg/presention-chart.svg",
    title: "LANDING.FEATURES.CARD.3.TITLE",
    description: "LANDING.FEATURES.CARD.3.DESCRIPTION",
    backgroundColor:
      "linear-gradient(90deg, rgba(92, 157, 255, 0.1), rgba(53, 130, 245, 0.1))",
  },
];
